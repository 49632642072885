import React from "react";
import styles from "./ios.module.css";
import { withStyles } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import apple from "../../../../Assets/apple.png";
import { Button } from "@mui/material";
import downloadFile from "../../../../utils/downloadFile";
import TutorialDialog from "../../../../TutorialDialog";

export default function IosIntegration() {
  const videoURL = process.env.REACT_APP_VIDEO_MOBILE;
  const [viewTutorialDialog, setViewTutorialDialog] = React.useState(false);
  const [stateCheck, setStateCheck] = React.useState(false);
  const apkLink = `${window.location.origin}/assets/Sample-App/passwordless-native-android-sdk.aar`;


  const styling = {
    backgroundColor: stateCheck ? "#001524" : "#fff",
    color: stateCheck ? "#fff" : "#001524",
    overflowY: "hidden",
    borderRadius: "0.4rem",
  };
  const sunLight = {
    color: stateCheck ? "#fff" : "#ff9d2d",
    fontSize: "1.2rem",
  };
  const moonLight = {
    color: stateCheck ? "#1976d2" : "#grey",
    fontSize: "1.2rem",
  };
  const linkColor = {
    color: stateCheck ? "rgb(188, 148, 88)" : "#0a61d1",
  };

  return (
		<div className={styles.webContainer}>
			<div className={styles.mainContainer}>
				<h1>Get Started - IOS</h1>
				<h2 id="starting-guide" className="pwl_heading">
					Starting Guide
				</h2>
				<p className="pwl_subheading">
					The Passwordless iOS SDK enables you to add FIDO-certified
					authentication in your native iOS application without having to
					redirect the user to any pages outside your application. For more
					robust functionality, it is likely that you will need to also leverage
					a Server SDK. The Server SDK makes requests to Passwordless's API
					easier by leveraging your API Credential. Check out the Passwordless
					Server SDK for a simplified integration.
				</p>

				<h2 id="create-application" className="pwl_heading">
					First: Create IoS Application
				</h2>
				<p className="pwl_subheading">
					Very easy to create a IoS application. Once you login you'll see a
					dashboard and <strong>add application</strong> button.
					<ol>
						<li className="pwl_subheading">
							Click on <strong>Add Application</strong> button
						</li>
						<li className="pwl_subheading">
							Select <strong>Mobile Application</strong> type
						</li>
						<li className="pwl_subheading">
							Fill the details of your app:
							<br />
							<ol>
								<li className="pwl_subheading">Add a app name</li>
								<li className="pwl_subheading">Add a app logo</li>
								<li className="pwl_subheading">
									Add a app website if you have(optional)
								</li>
								<li className="pwl_subheading">
									Add a Token Validity Time (in ms)
								</li>
								<li className="pwl_subheading">
									select App platoform: <strong>IOS</strong>
								</li>
								<li className="pwl_subheading">
									Enable/Disable Required Field
								</li>
							</ol>
						</li>
						<li className="pwl_subheading">
							<strong>Done!</strong>
						</li>
					</ol>
					<br />
					After creating your application, you will be redirected to the view
					application page where you can view your application and its settings.
					copy the Client Id, BASE URL and add to your application or download
					sample app to kickstart your web application.
				</p>
				<Accordion style={styling} size="small">
					<AccordionSummary
						expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
						aria-controls="panel1a-content"
						id="integrate-with-ios"
					>
						<Typography
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center'
							}}
						>
							<img width="30px" height="auto" src={apple} alt="nodejs" />{' '}
							Integration guide for IOS Application
						</Typography>
						<div
							style={{
								position: 'absolute',
								right: '3rem',
								top: '1.45rem',
								zIndex: 1
							}}
						>
							<Typography component="div">
								<Grid
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'flex-start',
										justifyContent: 'flex-end'
									}}
									component="label"
									container
									alignItems="center"
									spacing={1}
								>
									<Grid item>
										<Brightness7Icon style={sunLight} />
									</Grid>
									<Grid item>
										<Switch
											checked={stateCheck}
											onChange={() => setStateCheck(!stateCheck)}
											name="checkedC"
										/>
									</Grid>
									<Grid item>
										<NightsStayIcon style={moonLight} />
									</Grid>
								</Grid>
							</Typography>
						</div>
					</AccordionSummary>
					<AccordionDetails>
						<div>
							<h3>Integrate into IOS Application</h3>

							<p className="pwl_subheading">
								Download the sample app and follow the instructions.
								<span> </span>
								<a
									style={linkColor}
									href="https://github.com/BB-Passwordless/Passwordless-Sample-App"
									alt="smaple app"
									target="_blank"
									rel="noopener noreferrer"
								>
									click here to download
								</a>
							</p>
							<ui className="pwl_subheading" style={{ fontWeight: 'bold' }}>
								<li className="pwl_subheading">
									Create free account on{' '}
									<a style={linkColor} href="https://app.passwordless4u.com">
										https://app.passwordless4u.com
									</a>
								</li>
								<li className="pwl_subheading"> Create a IoS app</li>
								<li className="pwl_subheading">
									You will get client_id and baseURL
								</li>
								<li className="pwl_subheading">
									Use CLIENT_ID and BASE_URL to do passwordless authentication.
								</li>
							</ui>
							<br />
							<hr />
							<h3>Intergation into IOS application</h3>
							<h5>1. Download IOS SDK</h5>
							<Button
								variant="outlined"
								color="primary"
								style={{ textTransform: 'capitalize' }}
								onClick={() => {
									downloadFile(
										`${process.env.REACT_APP_SAMPLE_APP_DIR}/ios-sdk.zip`
									);
								}}
							>
								Download IOS SDK
							</Button>
							<h4>1. Clone Project</h4>
							<code>
								git clone
								https://github.com/BB-Passwordless/Passwordless-Sample-App.git
								cd passwordless-ios-sample-app
							</code>
							<h4>2. Changes Required:</h4>
							<ol>
								<li>
									<p>Add sdk files into your app</p>
									<ul>
										<li>libPasswordlessClientSdk.a</li>
										<li>PasswordlessClientSdk.h</li>
									</ul>
								</li>
								<li>
									<p>
										If your project is in swift then create bridging header file
										and import “PasswordlessClientSdk.h”.
									</p>
									<ul>
										<li>Eg - #import "lib/PasswordlessClientSdk.h"</li>
									</ul>
									<p>I have created lib group and putted sdk files into it</p>
								</li>
								<li>
									<p>Create sdk object</p>
									<ul>
										<li>var sdk = PasswordlessClientSdk()</li>
									</ul>
								</li>
								<li>
									<p>Declare 3 variables </p>
									<ul>
										<li>
											<p>Like -</p>
											<p>let baseUrl = "https://api.passwordless4u.com/v1"</p>
											<p>let originStr = "https://api.passwordless4u.com"</p>
											<p>
												let clientID =
												"eq-H8DjXiEHUaQGsDaz_tNlwfOOWvSdY2xkfpWKlJsNPuWHSqm"
											</p>
										</li>
									</ul>
								</li>
								<li>
									<p>
										Register - In viewDidLoad add delegate and load using base
										url and setup using website url/ origin
									</p>
									<ul>
										<li>
											<p>Like-</p>
											<p>sdk.sdkDelegate=self</p>
											<p>sdk.load(baseUrl)</p>
											<p>sdk.setupRegistrationClient(originStr, self)</p>
										</li>
									</ul>
								</li>
								<li>
									<p>
										Get application details - call sdks get application details
										method to get logo and app name
									</p>
									<ul>
										<li>
											<p>Like -</p>
											<p>sdk.getApplicationDetails(clientID)</p>
										</li>
									</ul>
								</li>
								<li>
									<p>
										Login - In viewDidLoad add delegate and load using base url
										and setup using website url/ origin
									</p>
									<ul>
										<li>
											<p>Like - </p>
											<p>sdk.sdkDelegate=self</p>
											<p>sdk.load(baseUrl)</p>
											<p>sdk.setupLoginClient(originStr, self)</p>
										</li>
									</ul>
								</li>
							</ol>
							<hr />
							<p>
								<strong>Delegate methods</strong>
							</p>
							<p>
								<strong>Add delegate</strong>
								<br />
								<code>
									class ViewController: UIViewController,
									PasswordlessClientSdkDelegate{' {'}
									<br />… <br />… <br />… <br /> {'} '}
								</code>
								<br />
								<br />
								<strong>Add delegate</strong>
								<br />
								<ol>
									<li>
										<code>
											func didReceivedResponse(fromSdk response: [String : Any]){' '}
											{' {'}
											<br />
											if response["verified"] == nil {' {'}
											<br />
											Let appName = "\(response["name"] as? String ?? "")" guard
											let appLogo = URL(string:response["logo"] as? String ?? ""
											) else {' {'} <br /> return <br /> {'}'} <br /> let data =
											try? Data(contentsOf: appLogo) if let imageData = data{' '}
											{' {'}
											<br />
											logoImage.image = UIImage(data: imageData)
											<br /> {'}'}
											<br />
											{'}'}
											<br />
											else
											{' {'}
											<br />
											let verified = response["verified"] as? Bool ?? false{' '}
											<br />
											print(verified) <br /> let errorCode =
											response["errorCode"] as? Int print(errorCode) <br />{' '}
											if(verified) <br />
											{' {'}
											<br />
											//Success
											<br />
											{'}'}
											<br /> else if(errorCode != nil){' {'}
											<br />
											let message = response["errorMessage"] as? String // error
											message
											<br />
											{'}'} else{' {'}
											<br />
											//error
											<br />
											{'}'}
											<br />
											{'}'}
											<br />
											{'}'}
											<br />
										</code>
									</li>
									<br />
									<li>
										<code>
											func didReceivedErrorSdk(_ error: String!) {'{'}
											<br />
											print(error)
											<br />
											{'}'}
										</code>
									</li>
								</ol>
							</p>

							<hr />
							<h4>Features</h4>
							<ul className="pwl_subheading">
								<li className="pwl_subheading">
									Authentication on same Platform
								</li>
								<li className="pwl_subheading">
									Remote Authentication without App
								</li>
								<li className="pwl_subheading">
									Remote Based Authentication with App - Android and ios
								</li>
								<li className="pwl_subheading">Push based Authentication</li>
							</ul>
						</div>
					</AccordionDetails>
				</Accordion>
			</div>
			<div className={styles.anchorMenu}>
				<p>Installation steps</p>
				<ul>
					<li className="pwl_subheading">
						<a href="#starting-guide" rel="noopener noreferrer">
							Start Guide
						</a>
					</li>
					<li className="pwl_subheading">
						<a href="#create-application" rel="noopener noreferrer">
							Create Application
						</a>
					</li>
					<li className="pwl_subheading">
						<a href="#integrate-with-ios" rel="noopener noreferrer">
							Integrate into IOS Application
						</a>
					</li>
				</ul>
				<p>Tutorial</p>
				<ul>
					<li className="pwl_subheading">
						<div
							className="pwl_tutorial_view_now"
							onClick={() => {
								setViewTutorialDialog(true);
							}}
						>
							View Now
						</div>
					</li>
				</ul>
			</div>

			{viewTutorialDialog && (
				<TutorialDialog
					open={viewTutorialDialog}
					setOpen={setViewTutorialDialog}
					title="Passwordless Mobile Integration Tutorial"
					videoUrl={videoURL}
				/>
			)}
		</div>
	);
}
