import React, { useState } from 'react'
import styles from './wordpress.module.css'
import { withStyles } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import wordpress from '../../../../Assets/wordpress.png'
import pwlMenu from "../../../../Assets/pwlMenu.png";
import screenshot1 from "../../../../Assets/screenshot1.png";
import screenshot2 from "../../../../Assets/screenshot2.png";
import screenshot3 from "../../../../Assets/screenshot3.png";
import screenshot4 from "../../../../Assets/screenshot4.png";
import screenshot5 from "../../../../Assets/screenshot5.png";
import userRegister from "../../../../Assets/userRegister.png";
import userLogin from "../../../../Assets/userLogin.png";
import userRemoteAuth from "../../../../Assets/userRemoteAuth.png";
import userRemoteAuthMobile from "../../../../Assets/userRemoteAuthMobile.jpeg";
import wordpressPluginScreenshot from "../../../../Assets/wordpressPluginScreenshot.png";
import assetlinks from './assetlinks.json'

import googlePlayStore from '../../../../Assets/googlePlaystore.png'
import appleAppStore from '../../../../Assets/appleAppStore.png'

import pwlUserConfigue from '../../../../Assets/pwlUserConfigue.png'

import Button from "@mui/material/Button";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TutorialDialog from '../../../../TutorialDialog';


export default function Index() {
  const videoURL = process.env.REACT_APP_VIDEO_WORDPRESS;
  const [viewTutorialDialog, setViewTutorialDialog] = useState(false);

  const [stateCheck, setStateCheck] = React.useState(false);
  const handleChange = (event) => {
    setStateCheck({
      ...stateCheck,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSaveToPC = (jsonData) => {
    const fileData = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    console.log("link: ", link)
    link.download = "assetlinks.json";
    link.href = url;
    link.click();

  };

  const styling = {
    backgroundColor: stateCheck ? "#001524" : "#fff",
    color: stateCheck ? "#fff" : "#001524",
    overflowY: "hidden",
    borderRadius: "0.4rem",
  };
  const sunLight = {
    color: stateCheck ? "#fff" : "#ff9d2d",
    fontSize: "1.5rem",
  };
  const moonLight = {
    color: stateCheck ? "#1976d2" : "grey",

    fontSize: "1.5rem",
  };
  const linkColor = {
    color: stateCheck ? "rgb(188, 148, 88)" : "#0a61d1",
  };
  const apkLink = "https://wordpress.org/plugins/passwordless-authentication/";
  
  return (
		<div className={styles.webContainer}>
			<div className={styles.mainContainer}>
				<h1>Get Started - Wordpress</h1>
				<h2 id="starting-guide" className="pwl_heading">
					Starting Guide
				</h2>
				<p className="pwl_subheading">
					The Passwordless Web SDK enables you to add FIDO-certified
					authentication in your WordPress website without having to redirect
					the user to any pages outside your application. For more robust
					functionality, it is likely that you will need to also leverage a
					Server SDK. The Server SDK makes requests to Passwordless's API easier
					by leveraging your API Credential. Check out the Passwordless Server
					SDK for a simplified integration.
				</p>

				<h2 id="create-application" className="pwl_heading">
					First: Create Passwordless's credentials for WordPress website
				</h2>
				<p className="pwl_subheading">
					Very easy to create a passwordless's credentials WordPress webiste.
					Once you login you'll see a dashboard and{' '}
					<strong>add application</strong> button.
					<ol>
						<li className="pwl_subheading">
							Click on <strong>Add Application</strong> button
						</li>
						<li className="pwl_subheading">
							Select <strong>Web/WordPress App</strong> type
						</li>
						<li className="pwl_subheading">
							Fill the details of your WordPress website:
							<br />
						</li>
						<li className="pwl_subheading">
							Clieck on <strong>Save and Next</strong>
						</li>
						<li className="pwl_subheading">
							<strong>Done!</strong>
						</li>
					</ol>
					After creating your application, you will be redirected to the view
					application page where you can view your application and its settings.
					copy the <strong>Client Id</strong> and <strong>Base URL</strong>.
				</p>
				<div>
					<p>
						<strong style={{ color: 'red' }}>Note: </strong> There are two
						plugins one is for WordPress admin panel authentication and other is
						for your wordpress website users authentication.
						<ul>
							<li>
								<strong>
									Passwordless's WordPress Account(Admin Panel) Authentication
									plugin
								</strong>{' '}
								is used for admin panel authentication(ex.
								www.example.com/wp-admin) to enter into wordpress
								developer/admin panel.
							</li>
							<br />
							<li>
								<strong>
									Passwordless's Wordpress website authentication plugin{' '}
								</strong>
								is general plugin used for wordpress website authentication(ex.
								www.example.com/user-login and/or www.example.com/user-register)
								to enter into wordpress website perticular page.
							</li>
						</ul>
					</p>
				</div>
				<Accordion style={styling} size="small">
					<AccordionSummary
						expandIcon={<ExpandMoreIcon style={{ color: 'lightgreen' }} />}
						aria-controls="panel1a-content"
						id="integrate-with-nodejs"
						style={{ height: '10vh' }}
					>
						<Typography
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center'
							}}
						>
							<img
								width="30px"
								height="auto"
								src={wordpress}
								alt="Wordpress"
								style={{ marginRight: '1rem' }}
							/>{' '}
							<strong>
								{' '}
								Integration guide of WordPress account(admin panel)
								authentication plugin
							</strong>
						</Typography>
						<div
							style={{
								position: 'absolute',
								right: '3rem',
								top: '1.45rem',
								zIndex: 1
							}}
						>
							{/* <Typography component="div">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}

                >
                  <div >
                    <Brightness7Icon style={sunLight} />
                  </div>
                  <div >
                    <Switch
                      checked={stateCheck}
                      onChange={() => setStateCheck(!stateCheck)}
                      name="checkedC"
                    />
                  </div>
                  <div >
                    <NightsStayIcon style={moonLight} />
                  </div>
                </div>
              </Typography> */}
						</div>
					</AccordionSummary>
					<AccordionDetails>
						<div>
							<h3>
								Intergation into WordPress{' '}
								<a
									href="https://wordpress.org/"
									target="_blank"
									rel="noreferrer"
								>
									<span style={{ color: '#3f51b5' }}>(WordPress)</span>
								</a>
							</h3>
							<h5>1. Download Wordpress plugin</h5>
							<a href={apkLink} target="_blank" rel="noreferrer">
								<Button
									color="primary"
									variant="contained"
									size="small"
									startIcon={<ArrowDownwardIcon />}
								>
									Download Plugin
								</Button>
							</a>
							<p>
								or search on wordpress marketplace with name of{' '}
								<strong>
									<em>passwordless authentication</em>
								</strong>{' '}
								and install/download it from there
							</p>
							<div>
								<img
									src={wordpressPluginScreenshot}
									width={'98%'}
									alt="Wordpress Plugin"
								/>
							</div>
							<h5 style={{ marginBottom: '0' }}>2.Activate the plugin</h5>
							<p>
								After activating the plugin, you will see a new menu{' '}
								<strong>Passwordless</strong>.
							</p>
							<div>
								<img src={pwlMenu} alt="pwlMenu" width={200} />
							</div>
							<h5>3.Configure</h5>
							<p>
								Click on <strong>Configure</strong> menu button and fill the
								details such as clientId and Base Url that you got from
								passwordless.
							</p>
							<div>
								<img src={screenshot1} alt="pwlMenu" width={'98%'} />
							</div>
							<h5> 4. Register yourself</h5>
							<p>
								After configuring the plugin, you will see a below{' '}
								<strong>Add Device</strong> menu button, click on it.
							</p>
							<div>
								<img src={screenshot2} alt="pwlMenu" width={'98%'} />
							</div>
							<p>
								<strong>Select the options</strong>
								<br />
								There are three option to select from.
								<ul>
									<li>
										<strong>Same platform or This Device: </strong> you can
										register your device with inbuilt methods such as biometric,
										pin or the device inbuilt security.
									</li>
									<li>
										<strong>Appless QR or Remote auth with appless QR: </strong>{' '}
										Register your remote device scanning the qr code.
									</li>
									<li>
										<strong>inApp QR or Remote auth with inApp QR: </strong>{' '}
										Register your remote device scanning the qr code with
										passwordless mobile app.
									</li>
									<li>
										<strong>Download the passwordless mobile app </strong>
										<br />
										<div className={styles.AppContainer}>
											<div className={styles.AppDownloadContainer}>
												<a
													href="https://play.google.com/store/apps/details?id=com.bluebricks.passwordless"
													rel="noopener noreferrer"
													alt="google play store"
													target="_blank"
												>
													<img
														style={{ objectFit: 'cover' }}
														width="120px"
														src={googlePlayStore}
														alt="Google Play Store"
													/>
												</a>
												<a
													href="https://apps.apple.com/us/app/passwordlessapp/id1587344101"
													rel="noopener noreferrer"
													alt="App store link"
													target="_blank"
												>
													<img
														width="120px"
														style={{ objectFit: 'cover' }}
														src={appleAppStore}
														alt="Google Play Store"
													/>
												</a>
												{/* <a
                            href="#"
                            rel="noopener noreferrer"
                            alt="Huawei store link"
                            target="_blank"
                          >
                            <img
                              width="35px"
                              style={{ objectFit: "cover" }}
                              src={Huawei}
                              alt="Huawei Store"
                            />
                          </a> */}
											</div>
										</div>
									</li>
								</ul>
							</p>
							<p>
								<strong style={{ color: 'red' }}>Note:</strong> If you are
								teammates is not admin level role for them, they can go to
								profile menu and go at the bottom there they see the same
								section for registration their devices. From there they can
								register their devices.
							</p>
							<div>
								<img src={screenshot3} alt="pwlMenu" width={'98%'} />
							</div>
							<h5> 5. Add more devices</h5>
							<p>
								Once you register there is button called "Add Device" in
								register page. from there you can add multiple devices to
								authenticate your wp-admin panel
							</p>
							<h5>6. Login</h5>
							<p>
								The general login flow for wp-admin panel is using email and
								password. but now you can see an option passwordless to
								authenticate and enter into wp-admin panel. select the option
								you have registered with and authenticate your self and get into
								wp-admin dashboard without remembering your password.
							</p>
							<div>
								<img src={screenshot4} alt="pwlMenu" width={'98%'} />
							</div>
							<h5>7. Success</h5>
							<p>
								Once passwordless authenticate successfully you'll enter into
								your wp-admin dashboard. its look like this one 😉
							</p>
							<div>
								<img src={screenshot5} alt="pwlMenu" width={'98%'} />
							</div>
							<h5>7. Remote auth</h5>
							<p>
								If you select the option appless QR to register/login then you
								need to scan the qr code using mobile then it will open below
								web page, approve it there.
							</p>
							<div>
								<img
									src={userRemoteAuth}
									alt="Passwordless Remote"
									width={'98%'}
								/>
							</div>
							<h2 style={{ marginBottom: '0' }}>In Mobile</h2>
							<div style={{ textAlign: 'justify' }}>
								<strong>Note: </strong>
								<p style={{ color: 'red', fontSize: '0.9rem' }}>
									For inApp Authentication: You need to create folder called{' '}
									<code>.well-known</code> in root directory (where your{' '}
									<code>wp-admin, wp-content, and wp-includes</code> folder are
									there) and in that folder you need to add assetlinks.json
									(JSON) file{' '}
									<Button
										onClick={(e) => handleSaveToPC(assetlinks)}
										size="small"
										variant="contained"
										style={{ textTransform: 'capitalize' }}
									>
										Download File!
									</Button>
								</p>
								<p style={{ fontSize: '0.9rem' }}>
									Cross verify whether file is added or not successfully. If
									your site is <code>https://www.example.com</code> then the
									link file path will be{' '}
									<code>
										https://www.example.com/.well-known/assetlinks.json
									</code>
								</p>
							</div>
							<div>
								<img
									src={userRemoteAuthMobile}
									alt="Passwordless Remote Auth"
									width={'50%'}
								/>
							</div>
						</div>
					</AccordionDetails>
				</Accordion>
				<br />
				<br />
				<Accordion style={styling} size="small">
					<AccordionSummary
						expandIcon={<ExpandMoreIcon style={{ color: 'lightgreen' }} />}
						aria-controls="panel1a-content"
						id="integrate-with-nodejs"
						style={{ height: '10vh' }}
					>
						<Typography
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center'
							}}
						>
							<img
								width="30px"
								height="auto"
								src={wordpress}
								alt="Wordpress"
								style={{ marginRight: '1rem' }}
							/>{' '}
							<strong>
								{' '}
								Integration Guide of WordPress website authentication plugin
							</strong>
						</Typography>
						<div
							style={{
								position: 'absolute',
								right: '3rem',
								top: '1.45rem',
								zIndex: 1
							}}
						>
							{/* <Typography component="div">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}

                >
                  <div >
                    <Brightness7Icon style={sunLight} />
                  </div>
                  <div >
                    <Switch
                      checked={stateCheck}
                      // onChange={() => setStateCheck(!stateCheck)}
                      name="checkedC"
                    />
                  </div>
                  <div >
                    <NightsStayIcon style={moonLight} />
                  </div>
                </div>
              </Typography> */}
						</div>
					</AccordionSummary>
					<AccordionDetails>
						<div>
							<h3>
								Intergation into WordPress website{' '}
								<a
									href="https://wordpress.org/"
									target="_blank"
									rel="noreferrer"
								>
									<span style={{ color: '#3f51b5' }}>(WordPress)</span>
								</a>
							</h3>
							<h5>1. Download Wordpress plugin</h5>
							<a href="#" target="_blank" rel="noreferrer">
								<Button
									color="primary"
									variant="contained"
									size="small"
									startIcon={<ArrowDownwardIcon />}
								>
									Download Plugin
								</Button>
							</a>
							<p>
								or search on wordpress marketplace with name of{' '}
								<strong>
									<em>passwordless authentication</em>
								</strong>{' '}
								and install/download it from there
							</p>
							<div>
								<img
									src={''}
									width={'98%'}
									alt="Wordpress Plugin at wordpress marketplace"
								/>
							</div>
							<h5 style={{ marginBottom: '0' }}>2.Activate the plugin</h5>
							<p>
								After activating the plugin, there few things will be generate
								automatically, one is data table into your wordpress database to
								save passwordless configuration, second is three pages will be
								generated into your wordpress website:
							</p>
							<ol>
								<li>User Register page</li>
								<li>User Login page</li>
								<li>User Remote Authentication page</li>
							</ol>
							<p>
								In those three page two pages url you can add into your website
								menu section/bar or add buttons to register user and login user
							</p>
							{/* <div>
                <img src={""} alt="Passwordless wordpress website menu at wp-panel" width={200} />
              </div> */}
							<h5>3.Configure</h5>
							<p>
								Click on <strong>Passwordless Menu</strong> menu button and fill
								the details such as clientId(that you got from
								passwordless.com.au), Base Url(that you got from
								passwordless.com.au), redirect path after registering the
								user(in url format ex. www.example.com/home) and redirect path
								after login user(in url format ex. www.example.com/home).
							</p>
							<div>
								<img
									src={pwlUserConfigue}
									alt="Passwordless Conguration"
									width={'98%'}
								/>
							</div>
							<h5> 4. Register flow</h5>
							<p>
								you can add register page url in your website menu section/bar
								or add buttons to register user
							</p>
							<div>
								<img src={userRegister} alt="Register Page" width={'98%'} />
							</div>
							<p>
								You will see form, there user can enter email address and below
								that select authenticaton method
								<br />
								<strong>Select the options</strong>
								<br />
								There are three option to select from.
								<ul>
									<li>
										<strong>Same platform or This Device: </strong> you can
										register your device with inbuilt methods such as biometric,
										pin or the device inbuild security.
									</li>
									<li>
										<strong>Appless QR or Remote auth with appless QR: </strong>{' '}
										Register your remote device scanning the qr code.
									</li>
									<li>
										<strong>inApp QR or Remote auth with inApp QR: </strong>{' '}
										Register your remote device scanning the qr code with
										passwordless mobile app.
									</li>
									<li>
										<strong>Download the passwordless mobile app </strong>
										<br />
										<div className={styles.AppContainer}>
											<div className={styles.AppDownloadContainer}>
												<a
													href="https://play.google.com/store/apps/details?id=com.bluebricks.passwordless"
													rel="noopener noreferrer"
													alt="google play store"
													target="_blank"
												>
													<img
														style={{ objectFit: 'cover' }}
														width="120px"
														src={googlePlayStore}
														alt="Google Play Store"
													/>
												</a>
												<a
													href="https://apps.apple.com/us/app/passwordlessapp/id1587344101"
													rel="noopener noreferrer"
													alt="App store link"
													target="_blank"
												>
													<img
														width="120px"
														style={{ objectFit: 'cover' }}
														src={appleAppStore}
														alt="Google Play Store"
													/>
												</a>
												{/* <a
                            href="#"
                            rel="noopener noreferrer"
                            alt="Huawei store link"
                            target="_blank"
                          >
                            <img
                              width="35px"
                              style={{ objectFit: "cover" }}
                              src={Huawei}
                              alt="Huawei Store"
                            />
                          </a> */}
											</div>
										</div>
									</li>
								</ul>
							</p>

							<h5> 5. Add more devices </h5>
							<p>
								Once you register there is button called "Add Device" in
								register page. from there you can add multiple devices to
								authenticate website using same email address.
							</p>

							<h5>6. Login flow</h5>
							<p>
								Here your site user enter the email and choose the option and
								hit login button after success it will redirect to the giving
								path(
								<i>
									a path you added in configuration page with name of redirect
									after login path
								</i>
								)
							</p>

							<div>
								<img
									src={userLogin}
									alt="Passwordless login page"
									width={'98%'}
								/>
							</div>
							<h5>7. Remote auth</h5>
							<p>
								If user select the option appless QR to register/login then user
								need to scan the qr code then they can go to below page approve
								it and authenticate there.
							</p>
							<div>
								<img
									src={userRemoteAuth}
									alt="Passwordless Remote"
									width={'98%'}
								/>
							</div>
							<h2>In Mobile</h2>

							<div>
								<img
									src={userRemoteAuthMobile}
									alt="Passwordless Remote Auth"
									width={'50%'}
								/>
							</div>
						</div>
					</AccordionDetails>
				</Accordion>
			</div>
			<div className={styles.anchorMenu}>
				<p>Installation steps</p>
				<ul>
					<li className="pwl_subheading">
						<a href="#starting-guide" rel="noopener noreferrer">
							Start Guide
						</a>
					</li>
					{/*  <li className="pwl_subheading">
              <a href="#create-application" rel="noopener noreferrer">
                Create Application
              </a>
            </li>
             <li className="pwl_subheading">
              <a href="#integrate-with-nodejs" rel="noopener noreferrer">
                Integrate into Nodejs Application
              </a>
            </li> */}
				</ul>
				<p>Tutorial</p>
				<ul>
					<li className="pwl_subheading">
						<div
							className="pwl_tutorial_view_now"
							onClick={() => {
								setViewTutorialDialog(true);
							}}
						>
							View Now
						</div>
					</li>
				</ul>
      </div>
      
			{viewTutorialDialog && (
				<TutorialDialog
					open={viewTutorialDialog}
					setOpen={setViewTutorialDialog}
					title="Passwordless WordPress Integration Tutorial"
					videoUrl={videoURL}
				/>
			)}
		</div>
	);
}
