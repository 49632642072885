import styles from "./gettingstarted.module.css";
import { useNavigate } from "react-router-dom";
const ApplicationTypes = [
	{ name: 'Web', path: 'Web' },
	{ name: 'Mobile', path: 'android' },
	{ name: 'OIDC', path: 'OIDC' },
	{ name: 'Radius', path: 'Radius' }
];

const GettingStarted = () => {
  const navigate = useNavigate();
  return (
    <div>
      <h1 id="🚀-getting-started" className="pwl_heading">
        <a href="/getting-started" className="header-anchor">
          #
        </a>
        🚀 Getting started
      </h1>
      <p className="pwl_subheading">
        We will now go into to details, what you need to know about the API and
        explain the terminology and concepts of the API.
      </p>
      <h2 id="introduction" className="pwl_heading">
        <a href="#introduction" className="header-anchor">
          #
        </a>
        Introduction
      </h2>

      <p className="pwl_subheading">The passwordless.dev service consists of three parts:</p>
      <ul>
         <li className="pwl_subheading">
          A small open-source client side library. The library helps you
          interact with the browser WebAuthn API and to connect with the public
          passwordless API.
        </li>{" "}
         <li className="pwl_subheading">
          A public API, for browsers to negotiate public keys and cryptographic
          details with the browser
        </li>{" "}
         <li className="pwl_subheading">
          A secret API, for your backend server to initiate key registrations,
          verify sign ins and retrieve keys for your users
        </li>
      </ul>

      <h2 id="✨-quick-start-with-copy-paste" className="pwl_heading">
        <a href="#✨-quick-start-with-copy-paste" className="header-anchor">
          #
        </a>{" "}
        ✨ Quick start with copy-paste{" "}
      </h2>
      <p className="pwl_subheading">
        If you just want to{" "}
        <strong>try signing in using your face/fingerprint</strong>, you can
        copy-paste the demo code to your application.
      </p>

      <p className="pwl_subheading">
        <a href="/demo-and-examples" className="">
          Get the code
        </a>
      </p>

      <h2 id="✅-getting-started-for-real" className="pwl_heading">
        <a href="#✅-getting-started-for-real" className="header-anchor">
          #
        </a>{" "}
        ✅ Getting started for real
      </h2>
      <p className="pwl_subheading">
        To really use Passwordless you need to add our library to your frontend
        and add a small backend integration. Integrating Passwordless into your
        app or website can begin as soon as you create a Passwordless account,
        and requires three steps:
      </p>
      <ol>
         <li className="pwl_subheading">
          <a
            href="https://www.passwordless4u.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Create Free Account
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                width="15"
                height="15"
                className="icon outbound"
              >
                <path
                  fill="currentColor"
                  d="M18.8,85.1h56l0,0c2.2,0,4-1.8,4-4v-32h-8v28h-48v-48h28v-8h-32l0,0c-2.2,0-4,1.8-4,4v56C14.8,83.3,16.6,85.1,18.8,85.1z"
                ></path>{" "}
                <polygon
                  fill="currentColor"
                  points="45.7,48.7 51.3,54.3 77.2,28.5 77.2,37.2 85.2,37.2 85.2,14.9 62.8,14.9 62.8,22.9 71.5,22.9"
                ></polygon>
              </svg>{" "}
              <span className="sr-only">(opens new window)</span>
            </span>
          </a>{" "}
          so Passwordless can authenticate your integration’s API requests.
        </li>{" "}
         <li className="pwl_subheading">Click on Add Application button</li>
         <li className="pwl_subheading">Select Application type</li>
         <li className="pwl_subheading">FIll Required Deatils</li>
         <li className="pwl_subheading">Voila! You are done!</li>
      </ol>
      <h2 id="✅-getting-started-for-real" className="pwl_heading">
        <a href="#✅-getting-started-for-real" className="header-anchor">
          #
        </a>{" "}
        Choose your application type
      </h2>
      <p className="pwl_subheading">
        Choose your application type to get started with Passwordless and get
        the best experience.
      </p>
      <div className={styles.cardContainer}>
        {ApplicationTypes.map((type, index) => (
          <div
            className={styles.card}
            key={index}
            id={type?.name}
            onClick={() => {
              navigate("/getting-started/" + type?.path);
            }}
          >
            <div className={styles.cardBody}>
              <h5 className={styles.cardTitle}>{type?.name}</h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default GettingStarted;
