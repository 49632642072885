import { useNavigate } from "react-router-dom";
import styles from "./home.module.css";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import PeopleImage from "../../Assets/people.png";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";
import Header from "../../Header";
const Home = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.homeContainer}>
      {/* <header className={styles.header_mainContainer}>
        <div
          className={styles.header_headiing_Container}
          onClick={() => window.open("https://www.passwordless4u.com")}
        >
          <h4 className={styles.header_heading}>
            Password<span style={{ color: "#f69953" }}>{`{ Less }`}</span>
          </h4>
        </div>
        <div>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" className={styles.heading_button}>
              {" "}
              Login
            </Button>
            <Button variant="contained" className={styles.heading_button}>
              {" "}
              Register
            </Button>
          </Stack>
        </div>
      </header> */}

      <Header />
      <section className={styles.section}>
        <Toolbar />
        <h1 className={styles.section_heading}>
          The Future of Authentication Is Passwordless4U
        </h1>
        <div className={styles.main_container}>
          <div className={styles.main_text_container}>
            <h1 className={styles.main_text_container_heading}>
              True Passwordless4U Customer Authentication Trusted By Industry
              Leaders
            </h1>
            <div className={styles.divider}></div>

            <p className={styles.para}>
              Eliminate passwords and accelerate the approval of multifactor security with a
              consistent practice that relishes your workers and users. Delivers robust authentication
              for users that is faster and smoother than ever before. Reduce Account Takeover (ATO)
              Fraud and enhance your user login engagement.
            </p>
            <Button
              variant="contained"
              className={styles.heading_button}
              onClick={() => {
                navigate("/getting-started");
              }}
            >
              Quick Start
            </Button>
          </div>
          <div className={styles.main_image_container}>
            <img src={PeopleImage} alt="people" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
