import React, { useCallback, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import styles from "./gencert.module.css";
export default function GenerateCertificate() {
  const [loading, setLoading] = useState(false);
  const countryName = useRef();
  const localityName = useRef();
  const organizationName = useRef();
  const organizationUnitName = useRef();
  const commonName = useRef();
  const emailAddress = useRef();
  const stateOrProvinceName = useRef();
  const validity = useRef();
  const digestAlgorithm = useRef("SHA256withRSA");
  const passphrase = useRef();
  const [generatedResultData, setGeneratedResultData] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const handleSubmit = useCallback(
    () => async (e) => {
      e.preventDefault();
      setLoading(true);
      const data = {
        countryName: countryName.current?.value,
        state: stateOrProvinceName.current?.value,
        locality: localityName.current?.value || "",
        organization: organizationName.current?.value,
        organizationUnit: organizationUnitName.current?.value || "",
        commonName: commonName.current?.value,
        emailAddress: emailAddress.current?.value || "",
        validDays: Number(validity.current?.value),
        sigalg: digestAlgorithm.current?.value,
      };
      console.log({ data });
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SAML_SERVER_URL}/certificate/create`,
          data
        );
        setGeneratedResultData(response.data);
        setShowResult(true);
        // scroll smooth to result section
        const resultSection = document.getElementById("result-section");
        resultSection.scrollIntoView({ behavior: "smooth" });

        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        toast.error(
          err?.errorMessage || err?.data?.errorMessage || "Something went wrong"
        );
      }
    },
    []
  );

  const copyToClipBoard = (e, text, name) => {
    e.preventDefault();
    navigator.clipboard.writeText(text);
    toast.success(`${name} copied to clipboard`);
  };
  const download = (e, text, name) => {
    e.preventDefault();
    const blob = new Blob([text], { type: "text/plain;charset=utf-8" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${name}.pem`;
    link.click();
  };
  return (
    <div className={styles.main_cnd}>
      <Box sx={{ p: 2.5 }}>
        <Typography variant="h5" spacing={2} sx={{ fontWeight: "bold" }}>
          Generate Self-Signed Certs
        </Typography>
        <Typography variant="body1" sx={{ width: "70%" }}>
          {" "}
          This tool creates self-signed certificates that can be used in this
          test environment. First, provide your data and then a public
          certificate and a private key. The CSR(certificate signing request)
          will be created for you
        </Typography>
      </Box>
      <br />
      <Divider />
      <br />
      <form onSubmit={handleSubmit()}>
        <Box sx={{ p: 2.5, width: "80%" }}>
          <Stack
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <TextField
              inputRef={countryName}
              label="Country Name"
              variant="outlined"
              size="small"
              fullWidth
              required
            />
            <TextField
              inputRef={localityName}
              label="Locality Name(optional)"
              variant="outlined"
              size="small"
              fullWidth
            />
          </Stack>
          <br />
          <Stack
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <TextField
              label="State or Province Name"
              inputRef={stateOrProvinceName}
              variant="outlined"
              size="small"
              fullWidth
              required
            />
            <TextField
              inputRef={organizationUnitName}
              label="Organization Unit Name(optional)"
              variant="outlined"
              size="small"
              fullWidth
            />
          </Stack>

          <br />
          <Stack
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <TextField
              inputRef={organizationName}
              label="Organization Name"
              variant="outlined"
              size="small"
              fullWidth
              required
            />
            <TextField
              inputRef={emailAddress}
              label="Email Address(optional)"
              variant="outlined"
              type="email"
              size="small"
              fullWidth
            />
          </Stack>
          <br />
          <Stack
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <TextField
              inputRef={commonName}
              label="Common Name, the domain"
              variant="outlined"
              size="small"
              // type="url"
              fullWidth
              required
            />
            <TextField
              label="Validity in Days"
              inputRef={validity}
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              required
            />
          </Stack>

          <br />
          <Stack
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <FormControl sx={{ width: "49.3%" }} size="small">
              <InputLabel id="demo-simple-select-label">
                Digest Algorithm
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Digest Algorithm"
                inputRef={digestAlgorithm}
                defaultValue={"SHA256withRSA"}
              >
                <MenuItem value="SHA256withRSA">SHA256</MenuItem>
                <MenuItem value="SHA512withRSA">SHA512</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <br />
          <center>
            <LoadingButton
              loading={loading}
              sx={{ textTransform: "capitalize" }}
              type="submit"
              variant="contained"
              color="primary"
            >
              Generate Certificate
            </LoadingButton>
          </center>
        </Box>
      </form>
      <br />
      <br />
      <Divider />
      <br />
      <Box
        sx={{ p: 2.5, display: showResult ? "default" : "none" }}
        id="result-section"
      >
        <br />
        <Stack
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Stack justifyContent="flex-start" direction="row" width="100%">
            <Stack
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              direction="column"
              width="100%"
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                sx={{ m: 0, p: 0 }}
              >
                <Typography variant="p" sx={{ fontWeight: "bold", m: 0, p: 0 }}>
                  {" "}
                  Certificate
                </Typography>
                <div>
                  <Tooltip title="Download Certificate" placement="top">
                    <IconButton
                      onClick={(e) =>
                        download(
                          e,
                          generatedResultData?.certificate,
                          "Certificate"
                        )
                      }
                    >
                      <DownloadIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Copy certificate" placement="top">
                    <IconButton
                      onClick={(e) =>
                        copyToClipBoard(
                          e,
                          generatedResultData?.certificate,
                          "Certificate"
                        )
                      }
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Stack>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={18}
                maxRows={18}
                value={generatedResultData?.certificate || ""}
                placeholder="X.509 cert"
                style={{ width: "100%", marginTop: "0rem", overflowY: "auto" }}
              />
            </Stack>
            <Stack width="100%"></Stack>
          </Stack>
          <Stack
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
            direction="column"
            width="100%"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              sx={{ m: 0, p: 0 }}
            >
              <Typography variant="p" sx={{ fontWeight: "bold", m: 0, p: 0 }}>
                {" "}
                Private Key
              </Typography>
              <div>
                <Tooltip title="Download Private Key" placement="top">
                  <IconButton
                    onClick={(e) =>
                      download(
                        e,
                        generatedResultData?.privateKey,
                        "Private key"
                      )
                    }
                  >
                    <DownloadIcon color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy Private Key" placement="top">
                  <IconButton
                    onClick={(e) =>
                      copyToClipBoard(
                        e,
                        generatedResultData?.privateKey,
                        "Private Key"
                      )
                    }
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Stack>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={18}
              maxRows={18}
              value={generatedResultData?.privateKey || ""}
              placeholder="X.509 cert"
              style={{ width: "100%", marginTop: "0rem", overflowY: "auto" }}
            />
          </Stack>
        </Stack>
        <br />
      </Box>
      <br />
      <br />
    </div>
  );
}
