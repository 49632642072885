import { AppBar, Toolbar, Typography, Stack, Button } from "@mui/material";
import styles from "./header.module.css";
import Logo from "../Assets/New/pwdlessLogoWhite.png";

const Header = () => {
  return (
		<AppBar
			position="fixed"
			sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
			className={styles.header}
		>
			<Toolbar>
				<div className={styles.header_mainContainer}>
					<div
						className={styles.header_headiing_Container}
						onClick={() => window.open('https://www.passwordless4u.com')}
          >
            <div className={styles.header_headiing_wrapper}>
              <img className={styles.header_logo} src={Logo} alt="LOGO" />
              <Typography
                variant="h5"
                noWrap
                component="div"
                style={{
                  fontWeight: 'bold',
                  color: 'white',
                }}
              >
							  Passwordless4U
              </Typography>
            </div>
					</div>
					<div>
						<Stack direction="row" spacing={2}>
							<Button
								variant="contained"
								className={styles.heading_button}
								onClick={() => window.open(`https://app.passwordless4u.com`)}
							>
								{' '}
								Login
							</Button>
							<Button
								variant="contained"
								className={styles.heading_button}
								onClick={() =>
									window.open('https://app.passwordless4u.com/register')
								}
							>
								{' '}
								Register
							</Button>
						</Stack>
					</div>
				</div>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
