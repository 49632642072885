import React from "react";
import styles from './saml.module.css';
import TutorialDialog from "../../../../TutorialDialog";

const SamlIntegration = () => {
  const videoURL = process.env.REACT_APP_VIDEO_SAML;
  const [viewTutorialDialog, setViewTutorialDialog] = React.useState(false);

  return (
		<div className={styles.webContainer}>
			<div className={styles.mainContainer}>
				<h1>SAML Integration</h1>
				<p>
					To integrate with the application, you need to use the following
					steps:
				</p>
				<ol>
					<li>
						<p>
							<strong>Create a new SAML application</strong>
						</p>
						<p>
							Login to the{' '}
							<a href="https://app.passwordless4u.com">Passwordless</a> and
							Click on Add SSO Application .
						</p>
						<p>Enter the following information:</p>
						<ul>
							<li>
								<strong>Name</strong>
								<p>The name of the application.</p>
							</li>

							<li>
								<strong>SP Entity Id</strong>
								<p>
									The application-defined unique identifier that is the intended
									audience of the SAML assertion. This is most often the SP
									Entity ID of your application.
								</p>
							</li>
							<li>
								<strong>Default Relay State</strong>
								<p>
									Identifies a specific application resource in an IDP initiated
									Single Sign-On scenario. In most instances this is blank.
								</p>
							</li>
							<li>
								<strong>NameId Format</strong>
								<p>
									Identifies the SAML processing rules and constraints for the
									assertion's subject statement. Use the default value of
									'Unspecified' unless the application explicitly requires a
									specific format.
								</p>
							</li>
							<li>
								<strong>SAML ACS URL</strong>
								<p>
									The location where the SAML assertion is sent with a HTTP
									POST. This is often referred to as the SAML Assertion Consumer
									Service (ACS) URL for your application.
								</p>
							</li>
							<li>
								<strong>Response</strong>
								<p>
									Determines whether the SAML authentication response message is
									digitally signed by the IDP or not. A digital signature is
									required to ensure that only your IDP generated the response
									message.
								</p>
							</li>
							<li>
								<strong>Assertion Encryption</strong>
								<p>
									Determines whether the SAML assertion is encrypted by the IDP
									or not. An encryption is required to ensure that only your IDP
									generated the assertion.
								</p>
							</li>
							<li>
								<strong>X509 Encryption Certificate</strong>
								<p>
									The X.509 certificate used to encrypt the SAML assertion. This
									is required if the Assertion Encryption is set to 'true'.
								</p>
							</li>
							<li>
								<strong>X509 Signing Certificate</strong>
								<p>
									The X.509 certificate used to digitally sign the SAML
									assertion. This is required if the Response is set to 'true'.
								</p>
							</li>
						</ul>
					</li>
					<li>
						<p>
							<strong>Invite an User</strong>
						</p>
						<p>View the Application and click on the Invite User button.</p>
						<p>Enter the following information:</p>
						<ul>
							<li>
								<strong>Full Name</strong>
								<p>The Full Name of the user.</p>
							</li>

							<li>
								<strong>Email</strong>
								<p>The email of the user.</p>
							</li>
						</ul>
					</li>
					<li>
						<strong>Download or Clone Repository</strong>
						<p>
							Download or clone the repository{' '}
							<a
								href={`${process.env.REACT_APP_SAMPLE_APP_BASE_URL}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								Sample SAML Application
							</a>{' '}
							from Github. Please note NodeJS SDK is required for running the
							application. If it is not installed, please install it using {' '}
							<a
								href="https://nodejs.org/en/download/"
								target="_blank"
								rel="noopener noreferrer"
							>
								this link.
							</a>
						</p>
					</li>
					<li>
						<p>
							<strong>
								Create an .env file and add following Details in that file.
							</strong>
						</p>
						<ul>
							<li>
								<strong>IDP_METADATA_URL</strong>
								<p>The URL of the SAML IDP Metadata.</p>
							</li>
						</ul>
					</li>
					<li>
						<strong>Run the application and login to the application.</strong>
					</li>
				</ol>
			</div>
			<div className={styles.anchorMenu}>
				{/* <p>Installation steps</p>
				<ul>
					<li className="pwl_subheading">
						<a href="#starting-guide" rel="noopener noreferrer">
							Start Guide
						</a>
					</li>
				</ul> */}
				<p>Tutorial</p>
				<ul>
					<li className="pwl_subheading">
						<div
							className="pwl_tutorial_view_now"
							onClick={() => {
								setViewTutorialDialog(true);
							}}
						>
							View Now
						</div>
					</li>
				</ul>
			</div>

			{viewTutorialDialog && (
				<TutorialDialog
					open={viewTutorialDialog}
					setOpen={setViewTutorialDialog}
					title="Passwordless SAML Integration Tutorial"
					videoUrl={videoURL}
				/>
			)}
		</div>
	);
};

export default SamlIntegration;
