export const InstallPackage = `npm i passwordless-bb

or 

yarn add passwordless-bb
`;

export const ImportPackage = `const {Passwordless} = require("passwordless-bb") 
or
import {Passwordless} from "passwordless-bb"

Passwordless.init("BASE_URL","CLIENT_ID")

in case of Browser use it directly
Passwordless.init("BASE_URL","CLIENT_ID")
`;

export const RegisterAnUser = `const RegisterAnUser = async (username) => {
    try{
    const response = await Passwordless.register(username)
    if(response.verified){
        // user is registered successfully
        // write your logic here
    }
    }
    catch (error){
        console.log(error)
    }   
}
`;

export const LoginAnUser = `const LoginAnUser = async (username) => {
    try{
    const response = await Passwordless.login(username)
    if(response.verified){
        // user is verified successfully with passwordless server
        // write your logic here
    }
    }
    catch (error){
        console.log(error)
    }
}`;

export const RemoteAuhtentication = `const GenerateQRCode = async (username, type, platform,method) => {
    //type : 1 for register, 2 for login
    //platform : "web" or "app". web for appless remote authentication, 
    //app for app-base remote authentication (you need to download Passwordless App)
    //method : "Qr" or "Push"

    const data = {
        username,
        type,
        platform,
        client_id : "CLIENT_ID"
        path: https://example.com/authenticate  // path which will be rendered after scanning qr code. 
                                                // Check Sample App for reference
        
        // you can add more data here like device, longitude, latitude, etc. 
        // Check sample App for more details.
    }

   try {
       // Use Push based only at login time and  
       // if your device is registered using app-base remote authentication
       
      if (method == "push") {
        remoteResponse = await Passwordless.sendPushNotification(userDetails);
        const device = remoteResponse?.devices?.join(", ");
        alert("push notification sent successfully to "+device);
      }
      else {
        remoteResponse = await Passwordless.generateQR(userDetails);
        // QR code is generated successfully
        // write your logic here to displace the QR code e.g.
        // QR Code is diffrent for each Platform so you need to check the platform and scan it accordingly
        qrImg.src = remoteResponse.url;
        type === 1
          ? $("#RegisterModal").modal("show")
          : $("#loginModal").modal("show");
      }

      const { transactionId } = remoteResponse;
      
      // wait for user to scan the QR code and do authentication
      const transactionResponse =
        await Passwordless.getTransactionStatusOnChange(transactionId);
      
      if (transactionResponse.status === "SUCCESS") {
         // User is verified successfully with passwordless server
        // write your logic here
        
      }
      else if(transactionResponse.status === "FAILED"){
         // User is not verified successfully with passwordless server
        // write your logic here
      }
      else {
        alert("Something went wrong");
      }
    } catch (error) {
        console.log(error);
    }
}`;
