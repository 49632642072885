import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Drawer from "./Drawer";
import SideRoutes from "./Routes";
import Home from "./Screens/home";
const Controller = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/" && <Drawer />}
      <Routes>
        <Route path="/" element={<Home />} />

        {React.Children.toArray(
          SideRoutes.map((route, index) => (
            <Route path={route.path} key={index} />
          ))
        )}
        <Route path="/getting-started/:appType" />
      </Routes>
    </>
  );
};

export default Controller;
