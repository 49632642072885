import { Light as SyntaxHighlighter } from "react-syntax-highlighter";

import js from "react-syntax-highlighter/dist/esm/languages/hljs/javascript";
import docco from "react-syntax-highlighter/dist/esm/styles/hljs/docco";
SyntaxHighlighter.registerLanguage("javascript", js);
const CodeHighlighter = ({ language, code }) => {
  
    return (
      <SyntaxHighlighter language={language || 'javascript'} style={docco}>
     {code}
      </SyntaxHighlighter>
    );
}

export default CodeHighlighter;