import React, { useState } from 'react';
import {
	Dialog,
	DialogContent,
	makeStyles,
	IconButton,
	Box,
	Typography,
    useTheme,
    useMediaQuery
} from '@material-ui/core';
import { Close } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
	dialogContent: {
		padding: theme.spacing(2),
		position: 'relative'
	},
	iframeContainer: {
		width: '100%',
		borderRadius: '8px',
		overflow: 'hidden',
		border: `0.5px solid transparent`,
		transition: 'border 0.3s',
		backgroundColor: `#F7FEF8`,
		'&:hover': {
			border: `1px solid #264653`
		},
		'&:focus': {
			border: `1px solid #264653`
		}
	},
	iframe: {
		width: '100%',
		height: '100%',
		border: 'none'
	}
}));

const TutorialDialog = ({ open, setOpen, title, videoUrl }) => {
	const classes = useStyles();
	const handleClose = () => setOpen(false);
    const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="lg"
			fullWidth
			fullScreen={fullScreen}
		>
			<DialogContent className={classes.dialogContent}>
				<IconButton
					onClick={handleClose}
					color="secondary"
					// size='small'
					style={{ position: 'absolute', right: 0, top: 0 }}
				>
					<Close />
				</IconButton>
				<Typography
					variant="h6"
					style={{ textAlign: 'center', color: '#264653' }}
				>
					{title}
				</Typography>
				{/* Video Component */}
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						mt: 3,
						height: '100%'
					}}
				>
					<Box
						className={classes.iframeContainer}
						sx={{
							// height: { xs: '80vh', sm: '80vh', md: '70vh', lg: '75vh' }
							height: '80vh'
						}}
					>
						<iframe
							src={videoUrl}
							title={title}
							allowFullScreen
							className={classes.iframe}
						></iframe>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default TutorialDialog;
