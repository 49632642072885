import React, { useState } from "react";
import styles from "./web.module.css";
import python from "../../../../Assets/python.png";
import ReactSampleApp from "./ReactSampleApp";
import reactjs from "../../../../Assets/reactjs.png";
import node from "../../../../Assets/node.png";
import NodeSampleApp from "./Nodejs";
import PythonSampleApp from "./PythonSampleApp";
import TutorialDialog from '../../../../TutorialDialog';

export default function WebIntegration() {
  const videoURL = process.env.REACT_APP_VIDEO_WEB;
  const [viewTutorialDialog, setViewTutorialDialog] = useState(false);
  const [state, setState] = useState({
    setNode: true,
    setPython: false,
    setReact: false,
  });

  return (
		<div className={styles.webContainer}>
			<div className={styles.mainContainer}>
				<h1 className="pwl_heading">Get Started - Web</h1>
				<h2 id="starting-guide" className="pwl_heading">
					Starting Guide
				</h2>
				<p className="pwl_subheading">
					The Passwordless Web SDK enables you to add FIDO-certified
					authentication in your web-based application without having to
					redirect the user to any pages outside your application. For more
					robust functionality, it is likely that you will need to also leverage
					a Server SDK. The Server SDK makes requests to Passwordless's API
					easier by leveraging your API Credential. Check out the Passwordless
					Server SDK for a simplified integration.
				</p>
				<h2 id="create-application" className="pwl_heading">
					First: Create Web Application
				</h2>
				<p className="pwl_subheading">
					Very easy to create a web application. Once you login you'll see a
					dashboard and <strong>add application</strong> button.
					<ol>
						<li className="pwl_subheading">
							Click on <strong>Add Application</strong> button
						</li>
						<li className="pwl_subheading">
							Select <strong> Application</strong> type
						</li>
						<li className="pwl_subheading">
							Fill the details of your application
						</li>
						<li className="pwl_subheading">
							<strong>Done!</strong>
						</li>
					</ol>
					<br />
					After creating your application, you will be redirected to the view
					application page where you can view your application and its settings.
					copy the Client Id, BASE URL and add to your application or download
					sample app to kickstart your web application.
				</p>
				<div className={styles.gridContainer}>
					<div onClick={() => setState({ setNode: !state.setNode })}>
						<img width="100px" height="70px" src={node} alt="nodejs" />
						<h3>Node.js</h3>
					</div>
					<div onClick={() => setState({ setPython: !state.setPython })}>
						<img width="70px" src={python} alt="Python" />
						<h3>Python</h3>
					</div>
					<div onClick={() => setState({ setReact: !state.setReact })}>
						<img width="70px" src={reactjs} alt="nodejs" />
						<h3>React.js</h3>
					</div>
				</div>
				{state.setNode && <NodeSampleApp />}
				{state.setPython && <PythonSampleApp />}
				{state.setReact && <ReactSampleApp />}
			</div>

			<div className={styles.anchorContainer}>
				<div className={styles.anchorMenu}>
					<p>Installation steps</p>
					<ul>
						<li className="pwl_subheading">
							<a href="#starting-guide" rel="noopener noreferrer">
								Start Guide
							</a>
						</li>
						<li className="pwl_subheading">
							<a href="#create-application" rel="noopener noreferrer">
								Create Application
							</a>
						</li>
						<li className="pwl_subheading">
							<a href="#integrate-with-nodejs" rel="noopener noreferrer">
								Integrate into Nodejs Application
							</a>
						</li>
						<li className="pwl_subheading">
							<a href="#integrate-with-python" rel="noopener noreferrer">
								Integrate into Python Application
							</a>
						</li>
						<li className="pwl_subheading">
							<a href="#integrate-with-wordpress" rel="noopener noreferrer">
								Integrate into WordPress Application
							</a>
						</li>
					</ul>
					<p>Tutorial</p>
					<ul>
						<li className="pwl_subheading">
							<div
								className="pwl_tutorial_view_now"
								onClick={() => {
									setViewTutorialDialog(true);
								}}
							>
								View Now
							</div>
						</li>
					</ul>
				</div>
			</div>

      {viewTutorialDialog &&
        <TutorialDialog
          open={viewTutorialDialog}
          setOpen={setViewTutorialDialog}
          title="Passwordless Web Integration Tutorial"
          videoUrl={videoURL}
        />
      }
		</div>
	);
}
