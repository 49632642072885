import { ExpandLess } from "@mui/icons-material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { Link } from "@mui/material";
import { NavLink, Route, Routes } from "react-router-dom";
import Header from "../Header";
import SideRoutes from "../Routes";
import Integration from "../Screens/Documentation/Integration";
import { withRouter } from "./withRouterHOC";
const drawerWidth = 280;

function ResponsiveDrawer(props) {
  const [menuOpen, setMenuOpen] = React.useState(true);

  const handleMenuOpenClick = (route) => {
    setMenuOpen(!menuOpen);
  };
  const activeRoute = (routeName) => {
    return props.location.pathname === routeName ? true : false;
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header />
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {React.Children.toArray(
              SideRoutes.map((route, index) => (
                <>
                  <NavLink
                    to={route.path}
                    style={{ textDecoration: "none" }}
                    key={index}
                  >
                    <ListItem
                      selected={activeRoute(route.path)}
                      onClick={() => handleMenuOpenClick(route)}
                    >
                      <ListItemText primary={route.sideBarName} />
                      {route.hasChildren &&
                        (menuOpen ? <ExpandLess /> : <ExpandMore />)}
                    </ListItem>
                  </NavLink>
                  {route.hasChildren && (
                    <Collapse
                      in={menuOpen}
                      timeout="auto"
                      unmountOnExit
                      style={{ paddingLeft: 20 }}
                    >
                      {React.Children.toArray(
                        route.subMenu.map((subRoute, index2) => (
                          <NavLink
                            to={subRoute.path}
                            style={{ textDecoration: "none" }}
                            key={`submenu-${index2}`}
                          >
                            <ListItem selected={activeRoute(subRoute.path)}>
                              <ListItemText primary={subRoute.sideBarName} />
                            </ListItem>
                          </NavLink>
                        ))
                      )}
                    </Collapse>
                  )}
                </>
              ))
            )}

            <Divider />
            <Link
              href={`https://api.${process.env.REACT_APP_HOST}/swagger`}
              style={{ textDecoration: "none" }}
            >
              <ListItem>Swagger Docs</ListItem>
            </Link>
          </List>
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
        style={{ padding: 20 }}
      >
        <Toolbar />
        <Routes>
          {SideRoutes.map((route, index) => (
            <Route path={route.path} element={route.Element} key={index} />
          ))}
          <Route
            path="/getting-started/web"
            element={<Integration appType="web" />}
          />
          <Route
            path="/getting-started/android"
            element={<Integration appType="android" />}
          />
          <Route
            path="/getting-started/ios"
            element={<Integration appType="ios" />}
          />
          <Route
            path="/getting-started/openId"
            element={<Integration appType="openId" />}
          />
          <Route
            path="/getting-started/saml"
            element={<Integration appType="saml" />}
          />
          <Route
            path="/getting-started/radius"
            element={<Integration appType="radius" />}
          />
          <Route
            path="/getting-started/wordpress"
            element={<Integration appType="wordpress" />}
          />
        </Routes>
      </Box>
    </Box>
  );
}

export default withRouter(ResponsiveDrawer);
