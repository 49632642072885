import GettingStarted from "../Screens/Getting-started";

import Integration from "../Screens/Documentation/Integration";
import Demo from "../Screens/Demo";
import Webauthn from "../Screens/WebAuthn/webAuthn";
import GenerateCertificate from "../Screens/GenerateCertificate";
const SideRoutes = [
  {
    path: "/getting-started",
    Element: <GettingStarted />,
    sideBarName: "Getting Started",
    hasChildren: true,
    subMenu: [
      {
        path: "/getting-started/web",
        Element: <Integration appType="web" />,
        sideBarName: "Web",
      },
      {
        path: "/getting-started/wordpress",
        Element: <Integration appType="wordpress" />,
        sideBarName: "WordPress",
      },
      {
        path: "/getting-started/android",
        Element: <Integration appType="android" />,
        sideBarName: "Android",
      },
      {
        path: "/getting-started/ios",
        Element: <Integration appType="ios" />,
        sideBarName: "IOS",
      },
      {
        path: "/getting-started/openId",
        Element: <Integration appType="openId" />,
        sideBarName: "OpenID Connect",
      },
      {
        path: "/getting-started/saml",
        Element: <Integration appType="saml" />,
        sideBarName: "SAML",
      },
      {
        path: "/getting-started/radius",
        Element: <Integration appType="radius" />,
        sideBarName: "Radius",
      },
    ],
  },

  {
    path: "/demo-and-examples",
    Element: <Demo />,
    sideBarName: "Demo and Examples",
  },

  {
    path: "/what-is-webauthn",
    Element: <Webauthn />,
    sideBarName: "What is WebAuthn and FIDO2?",
  },
  {
    path: "/generate-certificate",
    Element: <GenerateCertificate />,
    sideBarName: "Generate Certificate",
  },
];

export default SideRoutes;
