import Brightness7Icon from "@mui/icons-material/Brightness7";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import React from "react";
import android from "../../../../Assets/android.png";
import downloadFile from "../../../../utils/downloadFile";
import styles from "./android.module.css";
import TutorialDialog from "../../../../TutorialDialog";

export default function AndroidAppIntegration() {
  const videoURL = process.env.REACT_APP_VIDEO_MOBILE;
  const [viewTutorialDialog, setViewTutorialDialog] = React.useState(false);
  const [stateCheck, setStateCheck] = React.useState(false);
  const handleChange = (event) => {
    setStateCheck({
      ...stateCheck,
      [event.target.name]: event.target.checked,
    });
  };

  const styling = {
    backgroundColor: stateCheck ? "#001524" : "#fff",
    color: stateCheck ? "#fff" : "#001524",
    overflowY: "hidden",
    borderRadius: "0.4rem",
  };
  const sunLight = {
    color: stateCheck ? "#fff" : "#ff9d2d",
    fontSize: "1.2rem",
  };
  const moonLight = {
    color: stateCheck ? "#1976d2" : "grey",

    fontSize: "1.2rem",
  };
  const linkColor = {
    color: stateCheck ? "rgb(188, 148, 88)" : "#0a61d1",
  };
  const apkLink = `${window.location.origin}/assets/Sample-App/passwordless-native-android-sdk.aar`;
  return (
		<div className={styles.webContainer}>
			<div className={styles.mainContainer}>
				<h1>Get Started - Mobile</h1>
				<h2 id="starting-guide" className="pwl_heading">
					Starting Guide
				</h2>
				<p className="pwl_subheading">
					The Passwordless Android SDK enables you to add FIDO-certified
					authentication in your native Android application without having to
					redirect the user to any pages outside your application. For more
					robust functionality, it is likely that you will need to also leverage
					a Server SDK. The Server SDK makes requests to Passwordless's API
					easier by leveraging your API Credential. Check out the Passwordless
					Server SDK for a simplified integration.
				</p>

				<h2 id="create-application" className="pwl_heading">
					First: Create Mobile Application
				</h2>
				<p className="pwl_subheading">
					Very easy to create a mobile application. Once you login you'll see a
					dashboard and <strong>add application</strong> button.
					<ol>
						<li className="pwl_subheading">
							Click on <strong>Add Application</strong> button
						</li>
						<li className="pwl_subheading">
							Select <strong>Mobile Application</strong> type
						</li>
						<li className="pwl_subheading">
							Fill the details of your app:
							<br />
							<ol>
								<li className="pwl_subheading">Add a app name</li>
								<li className="pwl_subheading">Add a app logo</li>
								<li className="pwl_subheading">
									Add a app website if you have(optional)
								</li>
								<li className="pwl_subheading">
									Add a Token Validity Time (in ms)
								</li>
								<li className="pwl_subheading">
									select App platoform: <strong>Android</strong>
								</li>
								<li className="pwl_subheading">Add a app package name</li>
								<li className="pwl_subheading">Add a app APKHashKey</li>
								<li className="pwl_subheading">Click on Generate</li>
								<li className="pwl_subheading">
									Json file will be generated and automatically donwload as{' '}
									<code>assetlinks.json</code>
								</li>
								<li className="pwl_subheading">
									Click on <strong>Next</strong>
								</li>
								<li className="pwl_subheading">
									Enable/Disable Required Field
								</li>
							</ol>
						</li>
						<li className="pwl_subheading">
							<li className="pwl_subheading">
								Clieck on <strong>Save and Next</strong>
							</li>
						</li>
						<li className="pwl_subheading">
							<strong>Done!</strong>
						</li>
					</ol>
					After creating your application, you will be redirected to the view
					application page where you can view your application and its settings.
					copy the Client Id, BASE URL and add to your application or download
					sample app to kickstart your web application.
				</p>
				<Accordion style={styling} size="small">
					<AccordionSummary
						expandIcon={<ExpandMoreIcon style={{ color: 'lightgreen' }} />}
						aria-controls="panel1a-content"
						id="integrate-with-nodejs"
					>
						<Typography
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center'
							}}
						>
							<img width="50px" height="auto" src={android} alt="Android" />{' '}
							Integration guide for Android Application
						</Typography>
						<div
							style={{
								position: 'absolute',
								right: '3rem',
								top: '1.45rem',
								zIndex: 1
							}}
						>
							<Typography component="div">
								<Grid
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'flex-start',
										justifyContent: 'flex-end'
									}}
									component="label"
									container
									alignItems="center"
									spacing={1}
								>
									<Grid item>
										<Brightness7Icon style={sunLight} />
									</Grid>
									<Grid item>
										<Switch
											checked={stateCheck}
											onChange={() => setStateCheck(!stateCheck)}
											name="checkedC"
										/>
									</Grid>
									<Grid item>
										<NightsStayIcon style={moonLight} />
									</Grid>
								</Grid>
							</Typography>
						</div>
					</AccordionSummary>
					<AccordionDetails>
						<div>
							<h3>
								Intergation into Andorid application{' '}
								<span style={{ color: '#3f51b5' }}>(Java)</span>
							</h3>
							<h5>1. Download Android SDK</h5>
							<Button
								variant="outlined"
								color="primary"
								style={{ textTransform: 'capitalize' }}
								onClick={() => {
									downloadFile(
										`${process.env.REACT_APP_SAMPLE_APP_DIR}/passwordless-native-android-sdk.aar`
									);
								}}
							>
								Download Android SDK
							</Button>
							<h5>
								2. Add SDK into your project <code>libs</code> folder
							</h5>
							<h5>
								3. Add <code> libs</code> folder into project leven
								<code>build.gradle</code>
							</h5>
							<code>
								flatDir {'{'}
								dirs 'libs'
								{'}'}
							</code>
							<h5>4. Implement sdk into app level build.gradle</h5>
							<code>
								implementation(name:'PasswordlessClient_-release', ext:'aar'){' '}
								{'{'}
								<br />
								dependencies {'{'}
								<br />
								implementation 'com.android.volley:volley:1.2.1'
								<br />
								implementation
								'com.google.android.gms:play-services-fido:18.1.0'
								<br />
								{'}'}
								{'}'}
							</code>
							<h5>5. Create connector class object </h5>
							<code>Connector connector = new Connector(Activity);</code>
							<h5>6. Initialize using connector class object</h5>
							<code>
								connector.init("WEBSITE_URL","BASE_URL","RP_ID","CLIENT_ID");
							</code>
							<br />
							<p style={{ width: '60%', fontSize: '0.87rem' }}>
								RP_ID - where your .well-known/assetlinks.json published
								Ex-https://home.your_site.com.au/.well-known/assetlinks.json
								<hr />
								This is your assetlinks.json url Then home.your_site.com.au is
								your rp_id
								<hr />
								BASE_URL- you will get this when you create your mobile
								application on passwordless web
								https://app.passwordless4u.com/register
								<hr />
								MAIN_URL- you will get this when you create your mobile
								application on passwordless web
								https://app.passwordless4u.com/register
								<hr />
								CLIENT_ID – client id also get from web
								<hr />
								WEBSITE_URL - your website URL from dashboard
							</p>
							<h5>7. Register Method</h5>
							<code style={{ width: '60%', fontSize: '0.87rem' }}>
								connector.register(“username” new PasswordlessResponse() {'{'}
								<br />
								@Override public void response(int resultCode, String
								resultMessage, JSONObject resultJson) {' {'}
								<br />
								if(resultCode==0){'{'}
								<br />
								success
								<br />
								<br />
								{'}'} <br />
								else {'{'}
								<br />
								<br />
								fail
								<br />
								{'} '}
								{'}'});
							</code>

							<h5>8. Login Method</h5>
							<code>
								connector.login(“username” new PasswordlessResponse() {'{'}
								<br />
								@Override public void response(int resultCode, String
								resultMessage, JSONObject resultJson)
								{'{'}
								<br />
								if(resultCode==0){'{'}
								<br />
								success
								<br />
								<br />
								{'}'} <br />
								else {'{'}
								<br />
								<br />
								fail
								<br />
								{'}'} {'}'});
							</code>
							<h5>
								9. Now copy the following code and paste it after "onCreate" on
								your activity where you are using passwordless that means you
								need to pass activity result to passwordless
							</h5>
							<code>
								@Override protected void onActivityResult(int requestCode, int
								resultCode, Intent data)
								<br />
								{'{'}
								<br />
								super.onActivityResult(requestCode, resultCode, data);
								<br />
								connector.onActivityResult(requestCode,resultCode,data);
								<br />
								{'}'}
							</code>
							<h5>10. Interface methods</h5>
							<code>
								boolean init (String yourWebsiteUrl, String baseUrl, String
								raid, String clientId) throws Exception;
								<br />
								throws Exception; void login(String
								userName,PasswordlessResponse passwordlessResponse);
								<br />
								void register(String userName, PasswordlessResponse
								passwordlessResponse);
								<br />
								void onActivityResult(int requestCode, int resultCode, Intent
								data);
							</code>
							<h5>11. Done</h5>
						</div>
					</AccordionDetails>
				</Accordion>
			</div>
			<div className={styles.anchorMenu}>
				<p>Installation steps</p>
				<ul>
					<li className="pwl_subheading">
						<a href="#starting-guide" rel="noopener noreferrer">
							Start Guide
						</a>
					</li>
					{/*  <li className="pwl_subheading">
              <a href="#create-application" rel="noopener noreferrer">
                Create Application
              </a>
            </li>
             <li className="pwl_subheading">
              <a href="#integrate-with-nodejs" rel="noopener noreferrer">
                Integrate into Nodejs Application
              </a>
            </li> */}
				</ul>
				<p>Tutorial</p>
				<ul>
					<li className="pwl_subheading">
						<div
							className="pwl_tutorial_view_now"
							onClick={() => {
								setViewTutorialDialog(true);
							}}
						>
							View Now
						</div>
					</li>
				</ul>
			</div>

			{viewTutorialDialog && (
				<TutorialDialog
					open={viewTutorialDialog}
					setOpen={setViewTutorialDialog}
					title="Passwordless Mobile Integration Tutorial"
					videoUrl={videoURL}
				/>
			)}
		</div>
	);
}
