import React from "react";
import ReactPlayer from "react-player";
import CodeHighlighter from "./CodeHighlighter";
import { InstallPackage, ImportPackage,RegisterAnUser, LoginAnUser, RemoteAuhtentication } from "./ExamplesCode";
const Demo = () => {
  const videoURL = process.env.REACT_APP_VIDEO_API_DOCUMENTATION;
  return (
    <>
      <h1 className="pwl_heading">Demo and Examples</h1>
      <p className="pwl_subheading">This is a demo of the Passwordless API</p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <ReactPlayer url={videoURL} />
      </div>
      <div>
        <h1 id="Install The library" className="pwl_heading">
          <a href="#install-the-library" className="header-anchor">
            #
          </a>
          1. Install The library
        </h1>
        <p className="pwl_subheading">
          Install the library using npm or yarn and then import it in your
          project.
        </p>
        <CodeHighlighter code={InstallPackage} />
        <p className="pwl_subheading">
          You can also import script file in your project and use it directly
          with Following url.
        </p>
        <CodeHighlighter
          code={`<script src="https://cdn.jsdelivr.net/npm/passwordless-bb@2.0.4/index.js"></script>`}
        />
      </div>

      <div>
        <h1 id="Import-the-library" className="pwl_heading">
          <a href="#import-the-library" className="header-anchor">
            #
          </a>
          2. Import the library
        </h1>
        <p className="pwl_subheading">
          Import the library in your project using following snippet and then
          initialize it with CLIENT_ID and BASE_URL.
        </p>
        <CodeHighlighter code={ImportPackage} />
      </div>
      <div>
        <h1 id="register-an-user" className="pwl_heading">
          <a href="#register-an-user" className="header-anchor">
            #
          </a>
          3.Register An User
        </h1>
        <p className="pwl_subheading">
          Register an user using the following snippet.
        </p>
        <CodeHighlighter code={RegisterAnUser} />
      </div>
      <div>
        <h1 id="login-an-user" className="pwl_heading">
          <a href="#login-an-user" className="header-anchor">
            #
          </a>
          4.Login An User
        </h1>
        <p className="pwl_subheading">
          Login an user using the following snippet.
        </p>
        <CodeHighlighter code={LoginAnUser} />
      </div>

      <h1 className="pwl_heading">
        {" "}
        Lets see How we can add Remote Authenticator{" "}
      </h1>
      <p className="pwl_subheading">
        You can add a remote authenticator to your application. This is a very
        simple example.You will have to generate an unique QR code and scan it
        with your mobile device
      </p>

      <div>
        <h1 id="generate-a-qr" className="pwl_heading">
          <a href="#generate-a-qr" className="header-anchor">
            #
          </a>
          1.Generate a QR Code or a Push Notification
        </h1>
        <p className="pwl_subheading">
          Generate a QR code or Push Notification using the following snippet.
        </p>
        <CodeHighlighter code={RemoteAuhtentication} />
      </div>

      <div>
        <h1 id="sample-app" className="pwl_heading">
          Sample Apps and API Documentation
        </h1>
        <p className="pwl_subheading">
          <a href="https://github.com/BB-Passwordless/Passwordless-Sample-App">
            {" "}
            Click Here
          </a>
          to refer Ready Made Sample Apps and play with it.
        </p>
        <p className="pwl_subheading">
          <a href="https://api.passwordless4u.com/swagger"> Click Here</a>
          to refer Swagger API Documentation for more details.
        </p>
      </div>
    </>
  );
};

export default Demo;
