import React from "react";
import styles from "./web.module.css";
import { withStyles } from "@mui/material/styles";
import { makeStyles } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import reactjs from "../../../../Assets/reactjs.png";






export default function ReactSampleApp() {
    const [stateCheck, setStateCheck] = React.useState({
        checkedNode: false,
        checkedPython: false,
        checkedWordpress: false,
    })
  
    const handleChange = (event) => {
        setStateCheck({
            ...stateCheck,
            [event.target.name]: event.target.checked,
        });
    };
   
    const styling = {
      backgroundColor: stateCheck.checkedNode ? "#001524" : "#fff",
      color: stateCheck.checkedNode ? "#fff" : "#001524",
      overflowY: "hidden",
      borderRadius: "0.4rem",
      boxShadow: "0px 0px 5px #227c9d",
    };
    const sunLight = {
        color:  "#ff9d2d",
        fontSize: "1.2rem",
    };
    const moonLight = {
        color: stateCheck.checkedNode ? "#1976d2" : "#grey",
        fontSize: "1.2rem",
    };
    const linkColor = {
        color: stateCheck.checkedNode ? "rgb(188, 148, 88)" : "#0a61d1",
    };
    
    return (
      <>
        <Accordion size="small">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "black" }} />}
            aria-controls="panel1a-content"
            id="integrate-with-nodejs"
          >
            <Typography
             
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img width="30px" height="auto" src={reactjs} alt="reactjs" />{" "}
              <h3>Integration guide for ReactJs Application</h3>
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={styling}>
            <div>
              <div>
                <h3>Integrate into ReactJs Application</h3>
                <div
                  style={{
                    position: "absolute",
                    right: "3rem",
                    top: "1.45rem",
                    zIndex: 1,
                  }}
                >
                  <Typography component="div">
                    <Grid
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                      }}
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Brightness7Icon style={sunLight} />
                      </Grid>
                      <Grid item>
                        <Switch
                          checked={stateCheck.checkedNode}
                          onChange={handleChange}
                          name="checkedNode"
                        />
                      </Grid>
                      <Grid item>
                        <NightsStayIcon style={moonLight} />
                      </Grid>
                    </Grid>
                  </Typography>
                </div>
              </div>
              <p className="pwl_subheading">
                Download the sample app and follow the instructions.
                <br />
                <a
                  style={linkColor}
                  href="https://github.com/BB-Passwordless/Passwordless-Sample-App"
                  alt="smaple app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  click here to download
                </a>
              </p>
              <ui className="pwl_subheading" style={{ fontWeight: "bold" }}>
                 <li className="pwl_subheading">
                  Create free account on{" "}
                  <a style={linkColor} href="https://app.passwordless4u.com">
                    https://app.passwordless4u.com
                  </a>
                </li>
                 <li className="pwl_subheading"> Create a web app</li>
                 <li className="pwl_subheading">You will get client_id and baseURL</li>
                 <li className="pwl_subheading">
                  Use CLIENT_ID and BASE_URL to do passwordless authentication.
                </li>
              </ui>
              <br />
              <hr />
              <h3>Installation</h3>
              <h4>1. Clone Project</h4>
              <code>
                git clone
                https://github.com/BB-Passwordless/Passwordless-Sample-App.git
                <br />
                cd passwordless-react-sample-app
              </code>
              <h4>2. Install packages with npm or yarn</h4>
              <code>npm install or yarn install</code>
              <h4>
                3. Replace CLIENT_ID and BASE_URL with your own from
                /public/js/script.js and /public/js/webToMobile.js
              </h4>
              <h4>4. Run project by following command</h4>
              <code> npm start or yarn start</code>
              <h4>5. Create .env file with following content </h4>
              <code>
                REACT_APP_BASE_URL=BASE_URL # e.g.
                https://app.passwordless4u.com
                <br />
                REACT_APP_CLIENT_ID=YOUR_CLIENT_ID # You can get this from
                https://app.passwordless4u.com
                <br />
              </code>
              <h4>6. Run project by following command </h4>
              <code>
                npm start <br /> or <br /> yarn start
              </code>
              <h4>7. Project will start running on</h4>
              <code>http://localhost:3000</code>
              <hr />

              <h3>
                Running localhost on Remote devices (for development only)
              </h3>
              <ol className="pwl_subheading">
                 <li className="pwl_subheading">
                  go to <span> </span>
                  <a
                    style={linkColor}
                    href="https://ngrok.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://ngrok.com
                  </a>
                  <span> </span> and login or create account
                </li>
                 <li className="pwl_subheading">Download ngrok based on your OS</li>
                 <li className="pwl_subheading">unzip ngrok and follow ngrok documentation</li>
                 <li className="pwl_subheading">
                  Run ngrok with command
                  <code> ./ngrok http 3000</code>
                </li>
                 <li className="pwl_subheading">You will get a https url, copy it</li>
                 <li className="pwl_subheading">
                  Open Passwordless Dasboard and Edit your app website url, and
                  you are done. Now you can use appless and inApp feature to
                  login from remote devices in development.
                </li>
              </ol>
              <hr />
              <h4>Features</h4>
              <ul className="pwl_subheading">
                 <li className="pwl_subheading">Authentication on same Platform</li>
                 <li className="pwl_subheading">Remote Authentication without App</li>
                 <li className="pwl_subheading">Remote Based Authentication with App - Android and ios</li>
                 <li className="pwl_subheading">Push based Authentication</li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
      </>
    );
}