import Web from "./Web";
import Android from "./Android";
import IOS from "./IoS";
import OpenId from "./OpenId";
import Radius from "./Radius";
import WordPress from "./WordPress";
import Saml from "./SAML";
const Integration = ({ appType }) => {
  console.log("appType", appType);

  return (
		<>
			{appType === 'web' && <Web />}
			{appType === 'android' && <Android />}
			{appType === 'ios' && <IOS />}
			{appType === 'openId' && <OpenId />}
			{appType === 'radius' && <Radius />}
			{appType === 'wordpress' && <WordPress />}
			{appType === 'saml' && <Saml />}
		</>
	);
};

export default Integration;
