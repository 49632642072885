import React, { useState } from 'react';
import styles from './radius.module.css';
import TutorialDialog from '../../../../TutorialDialog';

export default function RadiusIntegration() {
    const videoURL = process.env.REACT_APP_VIDEO_RADIUS;
	const [viewTutorialDialog, setViewTutorialDialog] = useState(false);

	return (
		<div className={styles.webContainer}>
			<div className={styles.mainContainer}>
				<h1>Get Started - Radius</h1>

				<h2 id="create-application" className="pwl_heading">
					Create Radius Application
				</h2>
				<p className="pwl_subheading">
					To create a radius application. Once you login you'll see a dashboard
					and <strong>add an application</strong> button.
					<ol>
						<li className="pwl_subheading">
							Click on <strong>Add Application</strong> button
						</li>
						<li className="pwl_subheading">
							Select <strong>Application</strong> type Radius
						</li>
						<li className="pwl_subheading">
							Fill the application details:
							<ol>
								<li className="pwl_subheading">Application name</li>
								<li className="pwl_subheading">
									Client setting
									<ol>
										<li className="pwl_subheading">
											Display name (any name ex: Xyz VPN)
										</li>
										<li className="pwl_subheading">
											Primary IP address (client device ip address)
										</li>
										<li className="pwl_subheading">
											Shared secret (any at the time of login using radius its
											is need)
										</li>
									</ol>
								</li>
								<li className="pwl_subheading">
									Authentication options (dropdown)
									<ol>
										<li className="pwl_subheading">In App</li>
										<li className="pwl_subheading">Appless</li>
									</ol>
								</li>
								<li className="pwl_subheading">
									Click on <strong>Save and Next button</strong>
								</li>
								<li className="pwl_subheading">
									Application created successfully
								</li>
							</ol>
						</li>
					</ol>
				</p>

				<h2 id="manage-radius" className="pwl_heading">
					Manage Radius Applications And integrations
				</h2>
				<p className="pwl_subheading">
					<ol>
						<li className="pwl_subheading">
							In the application details page you will see radius primary and
							secondary server details that details you need to integrate at
							your end.
						</li>
						<li className="pwl_subheading">
							There is a tab called <strong>Radius Client</strong> in this
							section you can add multiple radius client just clicking on{' '}
							<strong>Add Radius button</strong>
						</li>
						<li className="pwl_subheading">
							To use passwordless at client side, that client user device needs
							to be registered with passwordless for that there is a tab called{' '}
							<strong>User list</strong> you can go there and click on{' '}
							<strong>Invite User Button</strong> and add client name and email
							and then passwordless will send invite link to registered user
							with their device.
						</li>
					</ol>
				</p>

				<h2 id="radius-with-ldap" className="pwl_heading">
					Use Radius with AD/LDAP
				</h2>
				<p className="pwl_subheading">
					<ol>
						<li className="pwl_subheading">
							To use radius application with LDAP for that you need to go to
							manage application that you created and there is a tab called{' '}
							<strong>User Source</strong> there you can enable LDAP
							Synchronization toggle button and save it you will see a LDAP
							configuration form
						</li>
						<li className="pwl_subheading">
							You can configure your LDAP and user for authentication with
							passwordless
						</li>
						<li className="pwl_subheading">
							Some field in LDAP you need to take care of such as
							<ol>
								<li className="pwl_subheading">
									<strong>Filter User:</strong> This field is used to find the
									LDAP user with matching attribute name. Ex. If you send a
									client an invitation link through your radius application and
									you have already enabled the LDAP then the client gets the
									invitation email link and opens that link and registers
									themself with email because Passwordless supports email based
									registration process. So if your LDAP user email is saved in
									email attribute then you need to give an email as a Filter
									User or if your LDAP user email is saved in emailAddress then
									you need to mention this attribute key in Filter User.
								</li>
								<li className="pwl_subheading">
									<strong>Attribute to filter:</strong> This field is for
									getting only specific attribute details from LDAP, if you only
									add email as attribute in this field then only email will be
									get from LDAP with comma separate you can add multiple
									attributes. This is used for internal use with passwordless to
									compare LDAP users with invited email. So make sure this field
									should be correct
								</li>
								<li className="pwl_subheading">
									<strong>User Password:</strong> This field is used to get the
									user password from LDAP and send it to the radius client to
									authenticate.
								</li>
							</ol>
						</li>
					</ol>
				</p>

				<h2 id="authentication-flow" className="pwl_heading">
					Authentication Flow
				</h2>
				<p className="pwl_subheading">
					<ol>
						<li className="pwl_subheading">
							Primary authentication initiates with the user submitting his
							Username and Password for on VPN Client
						</li>
						<li className="pwl_subheading">
							User request acts as an authentication request to RADIUS
							Server(AxiomProtect).
						</li>
						<li className="pwl_subheading">
							Axiom Protect Radius server passes user credentials to
							passwordless server.
						</li>
						<li className="pwl_subheading">
							Passwordless server validates against the credentials stored in AD
							(Active Directory) / Database.
						</li>
						<li className="pwl_subheading">
							Once the user's first level of authentication gets validated,
							Passwordless Server asks for a 2-factor authentication challenge
							to the user. (Inapp/ Apples)
						</li>
						<li className="pwl_subheading">
							Here the user submits the response/code using passwordless
							authentication.
						</li>
						<li className="pwl_subheading">
							User response is checked at Passwordless server & then forward to
							RADIUS Server side.
						</li>
						<li className="pwl_subheading">
							On successful 2nd factor authentication the user is granted access
							to login.
						</li>
					</ol>
				</p>
			</div>

			<div className={styles.anchorMenu}>
				<p>Integration steps</p>
				<ul>
					<li className="pwl_subheading">
						<a href="#create-application" rel="noopener noreferrer">
							Create Application
						</a>
					</li>
					<li className="pwl_subheading">
						<a href="#manage-radius" rel="noopener noreferrer">
							Manage Radius
						</a>
					</li>
					<li className="pwl_subheading">
						<a href="#radius-with-ldap" rel="noopener noreferrer">
							Radius with AD/LDAP
						</a>
					</li>
					<li className="pwl_subheading">
						<a href="#authentication-flow" rel="noopener noreferrer">
							Authentication Flow
						</a>
					</li>
				</ul>
				<p>Tutorial</p>
				<ul>
					<li className="pwl_subheading">
						<div
							className="pwl_tutorial_view_now"
							onClick={() => {
								setViewTutorialDialog(true);
							}}
						>
							View Now
						</div>
					</li>
				</ul>
			</div>

			{viewTutorialDialog && (
				<TutorialDialog
					open={viewTutorialDialog}
					setOpen={setViewTutorialDialog}
					title="Passwordless Radius Integration Tutorial"
					videoUrl={videoURL}
				/>
			)}
		</div>
	);
}
