import PwlAuth from "../../Assets/pwl-auth.png"
const Webauthn = () => {
    return (
      <div>
        <h1 id="what-is-fido" className="pwl_heading">
          <a href="#what-is-fido" className="header-anchor">
            #
          </a>
          What is FIDO?
        </h1>

        <p className="pwl_subheading">
          FIDO, or FIDO Alliance is a consortium that develops secure, open,
          standard, phishing proof authentication protocols. The FIDO Alliance
          was created in 2013, and now it has over three hundred members across
          the globe. FIDO has three protocols: UAF, U2F, and FIDO2. They are the
          same family of the protocols, since they are all origin based,
          challenge-response, phishing proof, digital signature authentication
          protocols.
        </p>
        <h1 id="what-is-webauth" className="pwl_heading">
          <a href="#what-is-webauthn" className="header-anchor">
            #
          </a>
          What is WebAuthn?
        </h1>
        <p className="pwl_subheading">
          WebAuthn is an API for managing public key credentials. In the
          nutshell, it is an interface to talk to FIDO authenticators. You give
          it a challenge. You get assertion back.
        </p>
        <h1 id="How-pwl-works?" className="pwl_heading">
          <a href="#How-pwl-works?" className="header-anchor">
            #
          </a>
          How Passwordless4U Authentication Works ?
        </h1>

        <p className="pwl_subheading">
          The idea behind passwordless4U authentication is that you don't have to
          remember your password. Instead, you can use your phone to
          authenticate yourself. This is called "Biometric Authentication" and
          it is a great way to secure your data.No password is sent over the
          internet, and so there is no password to compromise. Passwords/Pin
          still can be used during the verification process, as well as
          biometrics.
        </p>
        <img src={PwlAuth} alt="PwlAuth" width="80%" height={300} />
      </div>
    );
}

export default Webauthn;