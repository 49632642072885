import React from 'react';
import styles from './openid.module.css';
import TutorialDialog from '../../../../TutorialDialog';

const OpenIDIntegration = () => {
    const videoURL = process.env.REACT_APP_VIDEO_OIDC;
	const [viewTutorialDialog, setViewTutorialDialog] = React.useState(false);

	return (
		<div className={styles.webContainer}>
			<div className={styles.mainContainer}>
				<h1>Get Started - OpenID Connect (OIDC)</h1>

				<h2 id="what-is-oidc" className="pwl_heading">
					What is OpenID Connect?
				</h2>
				<p className="pwl_subheading">
					OpenID Connect (OIDC) is an identity layer built on top of the OAuth
					2.0 protocol. It allows clients to verify the identity of the end-user
					based on the authentication performed by an authorization server, as
					well as to obtain basic profile information about the end-user in an
					interoperable and REST-like manner.
				</p>

				<h2 id="create-oidc-application" className="pwl_heading">
					Create OIDC Application
				</h2>
				<p className="pwl_subheading">
					To create an OIDC application in Passwordless4U:
					<ol>
						<li className="pwl_subheading">
							Log in to your Passwordless4U dashboard
						</li>
						<li className="pwl_subheading">
							Click on the <strong>Add SSO - OpenID Application</strong> button
						</li>
						<li className="pwl_subheading">
							Select <strong>Web</strong> as the Application Type
						</li>
						<li className="pwl_subheading">
							Fill in the application details:
							<ul>
								<li>App integration name</li>
								<li>Logo (optional)</li>
								<li>Sign-in redirect URIs</li>
								<li>Sign-out redirect URIs (optional)</li>
							</ul>
						</li>
						<li className="pwl_subheading">
							Configure Token Endpoint Auth Method (default is 'none')
						</li>
						<li className="pwl_subheading">
							Select required grant types (default is 'authorization_code')
						</li>
						<li className="pwl_subheading">
							Click <strong>Save and Next</strong> to create the application
						</li>
					</ol>
				</p>

				<h2 id="oidc-flow" className="pwl_heading">
					OIDC Authentication Flow
				</h2>
				<p className="pwl_subheading">
					The typical OIDC authentication flow involves the following steps:
					<ol>
						<li className="pwl_subheading">
							The client initiates the flow by sending the end-user to the
							OpenID Provider (OP)
						</li>
						<li className="pwl_subheading">
							The OP authenticates the end-user and obtains authorization
						</li>
						<li className="pwl_subheading">
							The OP sends the end-user back to the client with an authorization
							code
						</li>
						<li className="pwl_subheading">
							The client requests tokens from the OP using the authorization
							code
						</li>
						<li className="pwl_subheading">
							The OP returns an ID token and usually an access token
						</li>
						<li className="pwl_subheading">
							The client validates the ID token and retrieves the end-user's
							subject identifier
						</li>
					</ol>
				</p>

				<h2 id="oidc-integration" className="pwl_heading">
					Integrating OIDC in Your Application
				</h2>
				<p className="pwl_subheading">
					To integrate OIDC in your application:
					<ol>
						<li className="pwl_subheading">
							Obtain the necessary credentials (Client ID and Client Secret)
							from your Passwordless4U dashboard
						</li>
						<li className="pwl_subheading">
							Configure your application with the OIDC provider endpoints
							(Authorization, Token, and UserInfo endpoints)
						</li>
						<li className="pwl_subheading">
							Implement the OIDC flow in your application, typically using a
							library specific to your programming language
						</li>
						<li className="pwl_subheading">
							Handle the returned tokens and user information in your
							application logic
						</li>
					</ol>
				</p>

				<h2 id="oidc-best-practices" className="pwl_heading">
					OIDC Best Practices
				</h2>
				<p className="pwl_subheading">
					When implementing OIDC:
					<ul>
						<li className="pwl_subheading">
							Always validate the ID token on the server-side
						</li>
						<li className="pwl_subheading">
							Use HTTPS for all OIDC-related communications
						</li>
						<li className="pwl_subheading">
							Implement proper token storage and management
						</li>
						<li className="pwl_subheading">Regularly rotate client secrets</li>
						<li className="pwl_subheading">
							Use state and nonce parameters to prevent CSRF and replay attacks
						</li>
					</ul>
				</p>

				<h2 id="oidc-sample-app" className="pwl_heading">
					Download or Clone Repository
				</h2>
				<p className="pwl_subheading">
					Download or clone the repository{' '}
					<a
						href={`${process.env.REACT_APP_SAMPLE_APP_BASE_URL}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						Sample OAuth Application
					</a>{' '}
					from Github. Please note NodeJS SDK is required for running the
					application. If it is not installed, please install it using{' '}
					<a
						href="https://nodejs.org/en/download/"
						target="_blank"
						rel="noopener noreferrer"
					>
						this link.
					</a>{' '}
				</p>
			</div>

			<div className={styles.anchorMenu}>
				<p>Integration steps</p>
				<ul>
					<li className="pwl_subheading">
						<a href="#what-is-oidc">What is OIDC?</a>
					</li>
					<li className="pwl_subheading">
						<a href="#create-oidc-application">Create OIDC Application</a>
					</li>
					<li className="pwl_subheading">
						<a href="#oidc-flow">OIDC Authentication Flow</a>
					</li>
					<li className="pwl_subheading">
						<a href="#oidc-integration">Integrating OIDC</a>
					</li>
					<li className="pwl_subheading">
						<a href="#oidc-best-practices">OIDC Best Practices</a>
					</li>
					<li className="pwl_subheading">
						<a href="#oidc-sample-app">OIDC Sample App</a>
					</li>
				</ul>
				<p>Reference</p>
				<ul>
					<li className="pwl_subheading">
						<a
							href="https://openid.net/connect/"
							target="_blank"
							rel="noopener noreferrer"
						>
							OpenID Connect Specification
						</a>
					</li>
				</ul>
				<p>Tutorial</p>
				<ul>
					<li className="pwl_subheading">
						<div
							className="pwl_tutorial_view_now"
							onClick={() => {
								setViewTutorialDialog(true);
							}}
						>
							View Now
						</div>
					</li>
				</ul>
			</div>

			{viewTutorialDialog && (
				<TutorialDialog
					open={viewTutorialDialog}
					setOpen={setViewTutorialDialog}
					title="Passwordless OIDC Integration Tutorial"
					videoUrl={videoURL}
				/>
			)}
		</div>
	);
};

export default OpenIDIntegration;